<template>
  <div class="member-index">
    <el-tabs
      type="card"
      :value="searchForm.action"
      @tab-click="handleTabChange"
    >
      <el-tab-pane
        v-for="item in categores"
        :label="item.TypeName"
        :name="item.id + ''"
        :key="item.id"
      ></el-tab-pane>
    </el-tabs>

    <template v-if="total">
      <el-form>
        <!-- <el-form-item label="收藏分类:">
        <el-radio
          v-for="item in categores"
          v-model="searchForm.action"
          :label="item.id"
          :key="item.id"
          @change="handleRadioChange"
        >{{ item.title }}({{ item.count }})</el-radio>
        <el-button size="mini" @click="handleAddCategory">新增分类</el-button>
      </el-form-item> -->
        <el-form-item>
          <el-button @click="onEdit" class="mr-10">{{
            isEdit ? "取消编辑" : "编辑"
          }}</el-button>
          <template v-if="isEdit"
            ><el-checkbox
              :value="selected.length > 0"
              :checked="selected.length > 0"
              :indeterminate="isIndeterminate"
              @change="handleChangeAll"
              >全选</el-checkbox
            >
            <!-- <el-dropdown class="custom-select" @command="handleCommand">
          <span class>
            批量移动至
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in categores"
              :label="item.title"
              :key="item.id"
              :command="item.id"
              :disabled="searchForm.action === item.id"
            >{{ item.title }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
            <el-button
              type="text"
              style="margin-left: 10px"
              icon="el-icon-delete"
              @click="handleDel"
              >移除</el-button
            ></template
          >
        </el-form-item>
      </el-form>
      <el-row :gutter="10">
        <el-col :span="8" class="item" v-for="item in list" :key="item.id">
          <el-card
            @click.native="
              isEdit ? handleTriggerSelect(item.id) : linkTo(item.gid)
            "
            shadow="never"
            :class="{ checked: selected.find((id) => id === item.id) }"
            :body-style="{ padding: '8px' }"
          >
            <el-image
              :src="$globalConfig.VUE_APP_IMAGE_URL + item.pic"
            ></el-image>
            <div class="item-title">{{ item.name }}</div>
          </el-card>
        </el-col>
      </el-row>
      <el-pagination
        :page-size="searchForm.pagenum"
        :current-page.sync="searchForm.page"
        :total="total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </template>
    <el-empty v-else description="空空如也~"></el-empty>
  </div>
</template>

<script>
import { getLikeList, getModelType, removeLike, delLike } from "@/api";
import { addLikeType } from "../../api";
export default {
  data() {
    return {
      type: "",
      searchForm: {
        action: "",
        type: "",
        page: 1,
        pagenum: 6,
      },
      selected: [],
      total: 0,
      categores: [],
      list: [],
      isEdit: false,
    };
  },
  computed: {
    isIndeterminate() {
      return (
        this.selected.length > 0 && this.selected.length !== this.list.length
      );
    },
  },
  mounted() {
    this.getCategory();
    // this.getData();
  },
  methods: {
    linkTo(id) {
      let routeData = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onEdit() {
      this.isEdit = !this.isEdit;
      if (!this.isEdit) {
        this.selected = [];
      }
    },
    handleChangeAll() {
      if (this.selected.length !== this.list.length) {
        this.selected = this.list.map((item) => item.id);
      } else {
        this.selected = [];
      }
    },
    async getCategory() {
      const res = await getModelType({});
      this.categores = res;
      this.searchForm.action = this.categores[0].id.toString();
      this.searchForm.type = this.categores[0].id.toString();
      this.searchForm.page = 1;
      this.getData();
    },
    async getData() {
      this.selected = [];
      const res = await getLikeList({
        ...this.searchForm,
      });
      this.list = res.data;
      this.total = res.count;
      // this.amount = res.count;
    },
    handleTabChange(tab) {
      this.list = [];
      this.selected = [];
      this.isEdit = false;
      this.searchForm.type = tab.name;
      this.searchForm.action = tab.name;
      // this.categores = [];
      this.getData();
    },
    handleRadioChange() {
      this.searchForm.page = 1;
      this.selected = [];
      this.getData();
    },
    handleCurrentChange(page) {
      this.searchForm.page = page;
      this.selected = [];
      this.getData();
    },
    async handleCommand(cmd) {
      if (!this.selected.length) {
        this.$message.warning("请选择需要移动的内容");
        return;
      }
      await removeLike({
        id: this.selected,
        categoreId: cmd,
      });
      this.$message.success("移动成功");
      this.getCategory();
    },
    handleTriggerSelect(id) {
      let index = this.selected.findIndex((item) => item === id);
      console.log("index >>", index);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }
    },
    async handleDel() {
      if (!this.selected.length) {
        this.$message.warning("请选择需要删除的内容");
        return;
      }
      await delLike({
        id: this.selected,
      });
      this.$message.success("删除成功");
      this.getData();
    },
    handleAddCategory() {
      const that = this;
      function valid(val) {
        if (!val) return "请输入分类名称";
        if (that.categores.find((item) => item.title === val)) {
          return "分类已存在";
        }
      }
      this.$prompt("请输入分类名", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: valid,
      }).then(async ({ value }) => {
        await addLikeType({
          title: value,
        });
        this.getCategory();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 9px;
    }
    .el-radio__input {
      display: none;
    }
  }
  .item {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .item-title {
    height: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .custom-select {
    // width: 120px;
    margin: 0 12px;
  }
  ::v-deep {
    .el-pagination {
      text-align: right;
      padding: 10px 0;
    }
  }
  .f-r {
    float: right;
  }
  .mr-10 {
    margin-right: 10px;
  }
}
</style>
